import { cva, VariantProps } from "class-variance-authority";
const loadingThreeDotsVariants = cva("h-1 w-1 rounded-full animate-bounce", {
  variants: {
    variant: {
      default: "bg-primary-foreground",
      secondary: "bg-secondary-foreground",
      destructive: "bg-destructive-foreground",
      outline: "bg-foreground group-hover:bg-primary-foreground",
      ghost: "bg-foreground group-hover:bg-primary-foreground",
      link: "bg-primary"
    }
  },
  defaultVariants: {
    variant: "default"
  }
});
type LoadingThreeDotsProps = VariantProps<typeof loadingThreeDotsVariants>;
function LoadingThreeDots({
  variant = "default"
}: LoadingThreeDotsProps) {
  return <div className="flex items-center space-x-1" data-sentry-component="LoadingThreeDots" data-sentry-source-file="loading-three-dots.tsx">
      <span className="sr-only">Loading...</span>

      <div className={`${loadingThreeDotsVariants({
      variant
    })} [animation-delay:-0.3s]`} />
      <div className={`${loadingThreeDotsVariants({
      variant
    })} [animation-delay:-0.15s`} />
      <div className={`${loadingThreeDotsVariants({
      variant
    })} [animation-delay:0s]`} />
    </div>;
}
export default LoadingThreeDots;