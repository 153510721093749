"use client";

import { signIn } from "next-auth/react";
import { useSearchParams } from "next/navigation";
import { useState } from "react";
import LoginComponent from "@/components/templates/login";
export default function LoginPage() {
  const [loading, setLoading] = useState(false);
  const searchParams = useSearchParams();
  const queryErrorMessage = searchParams.get("error");
  const [errorText, setErrorText] = useState<string | undefined>(queryErrorMessage ?? undefined);
  const handleLogin = async () => {
    setLoading(true);
    try {
      await signIn("whmcs");
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : "Unknown error";
      setErrorText(errorMessage);
      setLoading(false);
    }
  };
  return <LoginComponent errorText={errorText} loading={loading} handleLogin={handleLogin} data-sentry-element="LoginComponent" data-sentry-component="LoginPage" data-sentry-source-file="login.tsx" />;
}