"use client";

// Error components must be Client Components
import { Button } from "@/components/ui/button";
import Link from "@/components/atoms/link";
import { LINK_HOMEPAGE } from "@/lib/links";
import { captureException } from "@sentry/nextjs";
import { useEffect, useState } from "react";
export default function ErrorPage({
  error,
  reset
}: {
  error: Error & {
    digest?: string;
  };
  reset: () => void;
}) {
  const [errorId, setErrorId] = useState<string | undefined>(undefined);
  useEffect(() => {
    setErrorId(captureException(error));
  }, [error]);
  return <div className="flex min-h-[100dvh] flex-col items-center justify-center bg-background px-4 py-12 sm:px-6 lg:px-8" data-sentry-component="ErrorPage" data-sentry-source-file="error.tsx">
      <div className="mx-auto max-w-md text-center">
        <h2 className="mt-4 text-5xl font-bold tracking-tight text-foreground sm:text-6xl">
          500
        </h2>
        <p className="mt-4 text-lg text-muted-foreground">
          Oops, something went wrong on our end. We&apos;re working on fixing
          the issue.
        </p>
        <div className="mt-6 space-x-4">
          <Link href={LINK_HOMEPAGE} asButton prefetch={false} data-sentry-element="Link" data-sentry-source-file="error.tsx">
            Go to Homepage
          </Link>
          <Button onClick={() => reset()} data-sentry-element="Button" data-sentry-source-file="error.tsx">Try Again</Button>
        </div>
        <p className="text-xs pt-6 text-center text-muted-foreground">
          Error Id: <code>{errorId ?? "Loading..."}</code>
        </p>
      </div>
    </div>;
}