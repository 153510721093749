"use client";

import React, { ComponentProps } from "react";
import NextLink from "next/link";
import { useClientProductId } from "@/contexts/client-product-id";
import { cva, type VariantProps } from "class-variance-authority";
import { buttonVariants } from "../ui/button";
const linkVariants = cva("", {
  variants: {
    variant: {
      default: "underline",
      noUnderline: ""
    }
  },
  defaultVariants: {
    variant: "default"
  }
});
type LinkProps = ComponentProps<typeof NextLink> & {
  href: string;
} & ({
  asButton?: undefined;
  size?: undefined;
  variant?: VariantProps<typeof linkVariants>["variant"];
} | {
  asButton: true;
  variant?: VariantProps<typeof buttonVariants>["variant"];
  size?: VariantProps<typeof buttonVariants>["size"];
});
export default function Link({
  href,
  children,
  className,
  variant,
  prefetch,
  asButton,
  size,
  ...props
}: LinkProps) {
  const tokens = {
    clientProductId: useClientProductId()
  };
  const processedHref = Object.entries(tokens).reduce((acc, [key, value]) => value ? acc.replace(`:${key}`, value) : acc, href);
  const isExternal = !processedHref.startsWith("/") && !processedHref.startsWith("#");
  const classNameCombined = `${className} ${asButton ? buttonVariants({
    variant,
    size
  }) : linkVariants({
    variant
  })}`;
  if (isExternal) {
    return <a href={processedHref} target="_blank" rel="noopener noreferrer" className={classNameCombined} {...props}>
        {children}
      </a>;
  }
  return <NextLink href={processedHref} className={classNameCombined} prefetch={prefetch} {...props} data-sentry-element="NextLink" data-sentry-component="Link" data-sentry-source-file="link.tsx">
      {children}
    </NextLink>;
}