export const LINK_HOMEPAGE = "/";
export const LINK_SITES = "/:clientProductId";
export const LINK_CREATE_SITE = "/:clientProductId/create-site";
export const LINK_DATABASES = "/:clientProductId/databases";

export const LINK_SUPPORT_DOCUMENTATION = "https://citodocs.prostack.host/";
export const LINK_SUPPORT_TICKET =
  "https://www.prostack.uk/portal/submitticket.php";

export const LINK_SUPPORT_DOCUMENTATION_SSH =
  "https://citodocs.prostack.host/faq/#how-do-i-set-up-ssh-key-authentication";
